import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { LibraryBrowserContext } from 'features/LibraryBrowser/LibraryBrowserProvider';
import { useModal } from 'hooks/ui/useModal';
import { AppContext } from 'providers/application-context';
import { useContext } from 'react';
import { canDelete } from 'utils/authorizations';
import { ListItemContext } from '../ListItemProvider';

function Delete() {
  const { me } = useContext(AppContext);
  const { remove } = useContext(LibraryBrowserContext).libraryActions;
  const { library } = useContext(ListItemContext);
  const canIDelete = canDelete(me, library);
  const modal = useModal();

  const handleClick = (e: any) => {
    e.stopPropagation();
    modal.confirm({
      title: 'Remove Library?',
      content: `Are you sure you want to remove the library ${library.name}? This action cannot be undone.`,
      okText: 'Yes',
      cancelText: 'No',
      okButtonProps: {
        // loading: loading
      },
      onOk: async (close: any) => {
        if (!canIDelete) return;
        await remove(library);
        close();
      },
    });
  };

  return (
    <Tooltip placement="top" title="Remove library">
      <Button
        icon={<DeleteOutlined />}
        type="link"
        danger
        onClick={handleClick}
        disabled={!canIDelete}
      />
    </Tooltip>
  );
}

export default Delete;
