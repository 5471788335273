import { Form, InputNumber } from 'antd';
import useUpdateEffect from 'hooks/utils/useUpdateEffect';
import { useContext, useMemo } from 'react';
import useNodeValueInput from '../hooks/useNodeValueInput';
import { NodeValueInputContext } from '../NodeValueInputProvider';

type TInputVector3Props = { value?: number[]; onChange?: Function; labels?: string[] };

function InputVector3({ value, onChange, labels }: TInputVector3Props) {
  const { object, node } = useContext(NodeValueInputContext);
  const { handleChange } = useNodeValueInput(object?.value);

  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    // Value prop exists to make the component compatible with AntD forms
    if (value)
      return {
        x: value[0],
        y: value[1],
        z: value[2],
      };

    return {
      x: object?.value[0],
      y: object?.value[1],
      z: object?.value[2],
    };
  }, [object, value]);

  const submit = () => {
    const values = form.getFieldsValue();
    const valuesArray = Object.values(values) as number[];
    if (onChange) onChange(valuesArray);
    else handleChange(valuesArray);
  };

  useUpdateEffect(() => {
    form.resetFields();
  }, [node]);

  return (
    <Form form={form} initialValues={initialValues} layout="vertical">
      <Form.Item name="x" noStyle>
        <InputNumber
          step={0.01}
          addonBefore={labels ? labels[0] : 'X'}
          style={{ width: '100%' }}
          onPressEnter={submit}
          onBlur={submit}
          onStep={submit}
        />
      </Form.Item>
      <Form.Item name="y" noStyle>
        <InputNumber
          step={0.01}
          addonBefore={labels ? labels[1] : 'Y'}
          style={{ width: '100%' }}
          onPressEnter={submit}
          onBlur={submit}
          onStep={submit}
        />
      </Form.Item>
      <Form.Item name="z" noStyle>
        <InputNumber
          step={0.01}
          addonBefore={labels ? labels[2] : 'Z'}
          style={{ width: '100%' }}
          onPressEnter={submit}
          onBlur={submit}
          onStep={submit}
        />
      </Form.Item>
    </Form>
  );
}

InputVector3.defaultProps = {
  value: undefined,
  onChange: undefined,
  labels: undefined,
};

export default InputVector3;
