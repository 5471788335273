enum Attribute {
  Name = 'name',
  Type = 'type',
  Min = 'minOccurs',
  Max = 'maxOccurs',
  Ref = 'ref',
  Use = 'use',
}

export class SmtkElement extends Element {
  public nodeId: string = '';
}

export function getComponentElement(xmlDocument: Document) {
  return xmlDocument.querySelector('component');
}

export interface ComplexTypeContent {
  name: string;
  type: string;
  minOccurs: number;
  maxOccurs: number;
  documentation: string | undefined;
  appinfo: {
    var_type: string;
    default: string;
    labels?: string;
  };
  xsdElement: Element;
  choices?: ComplexTypeContent[];
}

export interface ComplexTypeAttribute {
  name: string;
  type: string;
  use: string;
  xsdElement: Element;
}

export interface ComplexType {
  name: string;
  documentation: string | undefined;
  contents: ComplexTypeContent[];
  attributes: ComplexTypeAttribute[];
  xsdElement: Element;
}

export type XsdTypes = Map<string, ComplexType>;

/**
 * Extracts the content types
 * @param contentElement
 * @returns
 */
export function extractElementContent(contentElement: Element): ComplexTypeContent {
  const content = {} as ComplexTypeContent;
  content.name =
    contentElement.getAttribute(Attribute.Name) || contentElement.getAttribute(Attribute.Ref) || '';
  content.type =
    contentElement.getAttribute(Attribute.Type) || contentElement.getAttribute(Attribute.Ref) || '';
  content.minOccurs = Number(contentElement.getAttribute(Attribute.Min) || '');
  const maxOccurs = contentElement.getAttribute(Attribute.Max);
  content.maxOccurs = maxOccurs !== 'unbounded' ? Number(maxOccurs) : Infinity;
  content.documentation = contentElement.querySelector('annotation > documentation')?.innerHTML;
  const appinfo = contentElement.querySelector('annotation > appinfo')?.innerHTML;
  if (appinfo) {
    content.appinfo = JSON.parse(appinfo);
  }
  content.xsdElement = contentElement;
  return content;
}

/**
 * Extract choice element
 * @param name
 * @param contentElement
 * @returns
 */
export function extractChoiceContent(name: string, contentElement: Element): ComplexTypeContent {
  const content = extractElementContent(contentElement);
  content.choices = [...contentElement.children].map((choiceElement: Element) =>
    extractElementContent(choiceElement),
  );
  content.name = `${name}_choice`;
  content.type = 'choice';
  content.xsdElement = contentElement;
  return content;
}

/**
 * Trace and remove all occurrence of variables with a given variableName from the document
 * @param variableName
 * @param xmlDoc
 */
export function removeVariableConnections(variableName: string, xmlDoc: Document) {
  const toList: Element[] = [];
  // remove
  xmlDoc.querySelectorAll('to').forEach((toElement) => {
    if (toElement.innerHTML === `behavior.${variableName}`) {
      toList.push(toElement);
    }
  });

  toList.forEach((toElement) => {
    if (toElement.parentElement?.children.length === 1) {
      toElement.parentElement.remove();
    } else {
      toElement.remove();
    }
  });

  xmlDoc
    .querySelectorAll(sanitizeQuery(`connection[name="behavior.${variableName}"]`))
    .forEach((connectionElement) => connectionElement.remove());
}

/**
 * Collects complex types form an XSD document as
 * @returns
 */
export function readComplexTypes(document: Document) {
  const types: Map<string, ComplexType> = new Map<string, ComplexType>();
  document.querySelectorAll(`complexType, schema>group`).forEach((complexTypeElement) => {
    const complexType = {} as ComplexType;

    complexType.name = complexTypeElement.getAttribute(Attribute.Name) || '';

    complexType.documentation = complexTypeElement.querySelector('annotation > documentation')
      ?.innerHTML;

    complexType.contents = [];
    complexTypeElement
      .querySelectorAll('sequence>element, sequence>group, sequence>choice, :scope>choice')
      .forEach((contentElement) => {
        switch (contentElement.localName) {
          case 'element': {
            complexType.contents.push(extractElementContent(contentElement));
            break;
          }
          case 'group': {
            complexType.contents.push(extractElementContent(contentElement));
            break;
          }
          case 'choice': {
            complexType.contents.push(extractChoiceContent(complexType.name, contentElement));
            break;
          }
          default: {
            break;
          }
        }
      });

    complexType.attributes = [];
    complexTypeElement.querySelectorAll('attribute').forEach((attributeElement) => {
      const attribute = {} as ComplexTypeAttribute;
      attribute.name = attributeElement.getAttribute(Attribute.Name) || '';
      attribute.type = attributeElement.getAttribute(Attribute.Type) || '';
      attribute.use = attributeElement.getAttribute(Attribute.Use) || '';
      complexType.attributes.push(attribute);
      attribute.xsdElement = attributeElement;
    });

    complexType.xsdElement = complexTypeElement;

    types.set(complexType.name, complexType);
  });
  return types;
}

function sanitizeQuery(query: string) {
  return CSS.escape(query);
}
