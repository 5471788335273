import {
  BarChartOutlined,
  BlockOutlined,
  BuildOutlined,
  DollarCircleOutlined,
  ProfileOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { AppContext } from 'providers/application-context';
import { useContext, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AdminConsoleContext } from '../AdminConsoleProvider';

export enum AdminConsolePaths {
  Members = 'members',
  Components = 'components',
  Systems = 'systems',
  Subscription = 'subscription',
  Settings = 'settings',
  Credits = 'credits',
}

export function SideMenu() {
  const match = useRouteMatch();
  const history = useHistory();

  const { me } = useContext(AppContext);
  const { organization } = useContext(AdminConsoleContext);

  const handleClick: MenuProps['onClick'] = (e: any) => {
    switch (e.key) {
      // case 'components':
      // case 'systems':
      //     history.push(`/${e.key}`, history.location.state)
      //     break;

      default:
        history.push(`${match.url}/${e.key}`, history.location.state);
        break;
    }
  };

  // Gets the correct path name so the selected menu item can sync with the current URL path
  const getPath = () => {
    const path = history.location.pathname.replace('/admin/', '');
    return path;
  };

  const isAdmin = useMemo(() => {
    const user: any | undefined = organization?.members?.find((member: any) => member.id === me.id);
    if (!user) return false;
    return user.role === 'admin';
  }, [me, organization]);

  return (
    <Menu
      onClick={handleClick}
      style={{ width: '100%' }}
      defaultSelectedKeys={[AdminConsolePaths.Members]}
      defaultOpenKeys={['manage']}
      mode="inline"
      selectedKeys={[getPath()]}
    >
      <Menu.ItemGroup title="Manage" key="manage">
        <Menu.Item icon={<UserOutlined />} key={AdminConsolePaths.Members}>
          Members
        </Menu.Item>
        <Menu.Item
          icon={<BlockOutlined />}
          key={AdminConsolePaths.Components}
          disabled={organization.is_blocked}
        >
          Components
        </Menu.Item>
        <Menu.Item
          icon={<BuildOutlined />}
          key={AdminConsolePaths.Systems}
          disabled={organization.is_blocked}
        >
          Systems
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Organization" key="organization">
        {isAdmin ? (
          <Menu.SubMenu title="Subscription" icon={<DollarCircleOutlined />}>
            <Menu.Item key={AdminConsolePaths.Subscription} icon={<ProfileOutlined />}>
              Details
            </Menu.Item>
            <Menu.Item key={AdminConsolePaths.Credits} icon={<BarChartOutlined />}>
              Credits Usage
            </Menu.Item>
          </Menu.SubMenu>
        ) : null}
        <Menu.Item icon={<SettingOutlined />} key={AdminConsolePaths.Settings}>
          Settings
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
}
