import { IComponent, ILibrary } from 'types/api-interfaces';
import React, { ReactNode, useMemo, useState } from 'react';
import useSelectedLibrary from './hooks/useSelectedLibrary';

type Mode = 'browse' | 'add';

interface IComponentLibraryBrowserContext {
  mode: Mode;
  setMode: (value: Mode) => void;
  selectedLibrary: {
    library: ILibrary | undefined;
    setLibrary: any;
    components: IComponent[];
    loading: boolean;
    add: (component: IComponent) => Promise<any>;
    remove: (component: IComponent) => Promise<any>;
    clear: () => void;
  };
}

interface IComponentLibraryBrowserProviderProps {
  children: ReactNode;
}

export const ComponentLibraryBrowserContext = React.createContext<IComponentLibraryBrowserContext>(
  {} as IComponentLibraryBrowserContext,
);

export function ComponentLibraryBrowserProvider({
  children,
}: IComponentLibraryBrowserProviderProps) {
  const [mode, setMode] = useState<Mode>('browse');
  const selectedLibrary = useSelectedLibrary();

  const providerValue = useMemo(
    () => ({
      mode,
      setMode,
      selectedLibrary,
    }),
    [mode, selectedLibrary],
  );

  return (
    <ComponentLibraryBrowserContext.Provider value={providerValue}>
      {children}
    </ComponentLibraryBrowserContext.Provider>
  );
}
