import useUpdateEffect from 'hooks/utils/useUpdateEffect';
import React, { ReactNode, useMemo, useState } from 'react';
import { ILibrary } from '../../common';
import { useLibraries } from './hooks/useLibraries';
import { LibraryScope } from './types';

interface ILibraryBrowserContext {
  libraries: ILibrary[];
  loading: boolean;
  scope: LibraryScope;
  setScope: (value: LibraryScope) => void;
  search: (value: string) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  libraryActions: {
    add: (library: ILibrary) => void;
    remove: (library: ILibrary) => Promise<void>;
    rename: (library: ILibrary, name: string) => void;
    setPublic: (library: ILibrary, toggle: boolean) => void;
    setVisible: (library: ILibrary, toggle: boolean) => void;
  };
}

interface ILibraryBrowserProviderProps {
  children: ReactNode;
}

export const LibraryBrowserContext = React.createContext<ILibraryBrowserContext>(
  {} as ILibraryBrowserContext,
);

export function LibraryBrowserProvider({ children }: ILibraryBrowserProviderProps) {
  const [scope, setScope] = useState<LibraryScope>('private');
  // const [searchTerm, setSearchTerm] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(1);
  const { libraries, loading, search, add, remove, rename, setPublic, setVisible } =
    useLibraries(scope);

  // Reset page number on scope change
  useUpdateEffect(() => {
    setCurrentPage(1);
  }, [scope]);

  const providerValue = useMemo(
    () => ({
      libraries,
      loading,
      scope,
      setScope,
      search,
      currentPage,
      setCurrentPage,
      libraryActions: {
        add,
        remove,
        rename,
        setPublic,
        setVisible,
      },
    }),
    [
      add,
      currentPage,
      libraries,
      loading,
      remove,
      rename,
      scope,
      search,
      setCurrentPage,
      setPublic,
      setVisible,
    ],
  );

  return (
    <LibraryBrowserContext.Provider value={providerValue}>
      {children}
    </LibraryBrowserContext.Provider>
  );
}
