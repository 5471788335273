import { message } from 'antd';
import { reportError } from 'simumatik-commons';

const serializeXML = (xmlDoc: Document | undefined): string => {
  try {
    if (!xmlDoc) throw new Error('XML document not initialized!');

    return new XMLSerializer()
      .serializeToString(xmlDoc)
      .replace(/xmlns="http:\/\/www.w3.org\/1999\/xhtml"/g, '');
  } catch (error) {
    message.error(error.message);
    reportError({
      message: error.message,
      url: error.name,
      at: 'ObjectXML.serializeXML',
    });
    return '';
  }
};

export default serializeXML;
