import { createContext, useMemo } from 'react';
import {
  IUseLocalServicesLauncher,
  useLocalServicesLauncher,
} from './hooks/useLocalServicesLauncher';

interface ILocalServicesContext {
  serviceLauncher: IUseLocalServicesLauncher;
}
export const LocalServicesContext = createContext<ILocalServicesContext>(
  {} as ILocalServicesContext,
);

interface ILocalServicesProvider {
  children: any;
}

function useLocalServicesProvider() {
  const serviceLauncher = useLocalServicesLauncher();

  return {
    serviceLauncher,
  };
}

export function LocalServicesProvider(props: ILocalServicesProvider) {
  const { children } = props;
  const { serviceLauncher } = useLocalServicesProvider();

  const providerValue = useMemo(() => {
    return { serviceLauncher };
  }, [serviceLauncher]);

  return (
    <LocalServicesContext.Provider value={providerValue}>{children}</LocalServicesContext.Provider>
  );
}
