import { useQuery } from '@tanstack/react-query';
import React, { ReactNode, useContext, useMemo } from 'react';
import { IMember, fetchOrganization } from 'services/organization';
import { AppContext } from '../../providers/application-context';

interface IOrganizationData {
  created: string;
  id: string;
  is_blocked: boolean;
  logo_url: string;
  members: IMember[];
  modified: string;
  name: string;
  organization_type: string | null;
  seats: number;
  site_url: string;
}

interface IAdminConsoleContext {
  organization: IOrganizationData;
  updateOrganization: () => void;
  adminPermissions: boolean;
  managerPermissions: boolean;
  loading: boolean;
}
interface IAdminConsoleProviderProps {
  children: ReactNode;
}

export const AdminConsoleContext = React.createContext({} as IAdminConsoleContext);

export function AdminConsoleProvider(props: IAdminConsoleProviderProps) {
  const { children } = props;
  const { me } = useContext(AppContext);
  const { data, isLoading } = useQuery(['organization', me.organization?.id], () =>
    fetchOrganization(me.organization?.id),
  );

  const providerValue = useMemo(() => {
    return {
      organization: data ?? ({} as IOrganizationData),
      updateOrganization: () => {},
      adminPermissions: me.organization?.is_admin,
      managerPermissions: me.organization?.can_manage,
      loading: isLoading,
    };
  }, [me.organization, data, isLoading]);

  return (
    <AdminConsoleContext.Provider value={providerValue}>{children}</AdminConsoleContext.Provider>
  );
}
