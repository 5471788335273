import { IXMLNodeInfo } from 'features/ComponentPage/ComponentEditor/interfaces';
import React, { ReactNode, useMemo } from 'react';
import { smtk_type } from 'simumatik-commons';
import { Value } from './types';

interface IProps {
  children: ReactNode;
  node: IXMLNodeInfo;
  object: smtk_type<any> | null;
  updateValue: (value: Value) => void;
  xmlDoc: Document;
}

interface IContext {
  node: IXMLNodeInfo;
  object: smtk_type<any> | null;
  updateValue: (value: Value) => void;
  xmlDoc: Document;
}

export const NodeValueInputContext = React.createContext<IContext>({} as IContext);

export function NodeValueInputProvider(props: IProps) {
  const { children, node, object, updateValue, xmlDoc } = props;

  const providerValue = useMemo(() => {
    return {
      node,
      object,
      updateValue,
      xmlDoc,
    };
  }, [node, object, updateValue, xmlDoc]);

  return (
    <NodeValueInputContext.Provider value={providerValue}>
      {children}
    </NodeValueInputContext.Provider>
  );
}
