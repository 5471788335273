import { List, Typography } from 'antd';
import useMediaQuery from 'hooks/utils/useMediaQuery';
import { ComponentLibraryBrowserContext } from 'providers/ComponentLibraryBrowserProvider';
import { AppContext } from 'providers/application-context';
import { useContext, useMemo } from 'react';
import { ILibrary } from 'types/api-interfaces';
import { LibraryBrowserContext } from '../LibraryBrowserProvider';
import ListItemProvider from './ListItemProvider';
import ActionsMenu from './actions/ActionsMenu';
import Delete from './actions/Delete';
import Rename from './actions/Rename';
import TogglePublic from './actions/TogglePublic';
import ToggleVisibility from './actions/ToggleVisibility';

const { Meta } = List.Item;
const { Text } = Typography;

function ListItem(props: { library: ILibrary }) {
  const { library } = props;
  const { me } = useContext(AppContext);
  const { scope } = useContext(LibraryBrowserContext);
  const { selectedLibrary } = useContext(ComponentLibraryBrowserContext);
  const collapseActions = useMediaQuery('(max-width: 986px)');

  const actions = useMemo(() => {
    if (collapseActions) return [<ActionsMenu key="collapseActions" />];
    if (scope === 'private' && me.organization)
      return [
        <TogglePublic key="togglePublic" />,
        <ToggleVisibility key="toggleVisibility" />,
        <Rename key="rename" library={library} />,
        <Delete key="delete" />,
      ];
    if (scope === 'private')
      return [<Rename key="rename" library={library} />, <Delete key="delete" />];
    return [];
  }, [collapseActions, me.organization, library, scope]);

  const handleClick = () => {
    selectedLibrary.setLibrary(library);
  };

  return (
    <ListItemProvider library={library}>
      <List.Item
        className="hover:cursor-pointer hover:bg-black/5"
        onClick={handleClick}
        actions={actions}
      >
        <Meta
          title={
            <Text ellipsis className="w-[10vw]">
              {library.name}
            </Text>
          }
          description={library.owner_name}
        />
      </List.Item>
    </ListItemProvider>
  );
}

export default ListItem;
