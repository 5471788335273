import { Switch, Tooltip } from 'antd';
import { LibraryBrowserContext } from 'features/LibraryBrowser/LibraryBrowserProvider';
import { AppContext } from 'providers/application-context';
import { useContext, useEffect, useState } from 'react';
import { canModify } from 'utils/authorizations';
import { ListItemContext } from '../ListItemProvider';

function ToggleVisibility() {
  const { me, ui } = useContext(AppContext);
  const { setVisible } = useContext(LibraryBrowserContext).libraryActions;
  const { library } = useContext(ListItemContext);
  const canIModify = canModify(me, library);
  const [value, setValue] = useState(library.visible);

  // Update visibility in API and state
  const handleChange = async (checked: boolean) => {
    setVisible(library, checked);
  };

  const getTooltipTitle = () => {
    if (ui.libScope === 'false' && !me.organization)
      return `Individual users cannot share libraries with other users`;
    if (ui.libScope === 'false' && me.organization)
      return `Toggle library visibility to other users in ${me.organization?.name}`;
    return null;
  };

  useEffect(() => {
    setValue(library.visible);
  }, [library]);

  return (
    <Tooltip title={getTooltipTitle()}>
      <Switch
        checked={value}
        checkedChildren={ui.libScope === 'false' && !me.organization ? 'Private' : 'Visible'}
        unCheckedChildren={ui.libScope === 'false' && !me.organization ? 'Private' : 'Hidden'}
        onChange={handleChange}
        disabled={!canIModify || (ui.libScope === 'false' && !me.organization)}
        onClick={(_, e) => e.stopPropagation()}
      />
    </Tooltip>
  );
}

export default ToggleVisibility;
