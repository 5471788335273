const AvatarConstants = {
  DEFAULT_WIDTH: 42,
  colorsPalette: [
    '#bdc3c7',
    '#6f7b87',
    '#2c3e50',
    '#2f3193',
    '#662d91',
    '#922790',
    '#ec2176',
    '#ed1c24',
    '#f36622',
    '#f8941e',
    '#fab70f',
    '#fdde00',
    '#d1d219',
    '#8ec73f',
    '#00a650',
    '#00aa9c',
    '#00adef',
    '#0081cd',
    '#005bab',
  ],
};

export default AvatarConstants;
