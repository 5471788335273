/* eslint-disable import/prefer-default-export */
import { IMe } from 'types/api-interfaces';
import { apiClient } from 'lib/axios';

export const renameUser = async (name: string) => {
  const { data } = await apiClient.patch<IMe>('/users/me', { name });
  return data;
};

export async function fetchMe() {
  const { data } = await apiClient.get<IMe>('/users/me');
  return data;
}

export async function patchMe(payload?: Partial<IMe>) {
  const { data } = await apiClient.patch<IMe>('/users/me', payload);
  return data;
}
