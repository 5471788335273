import { apiClient } from 'lib/axios';

const params = new URLSearchParams();
// Load beta XSD files if we are on beta
if (window.location.hostname === 'beta.simumatik.com') params.append('version', 'beta');

export async function fetchComponentXSD(signal?: AbortSignal): Promise<string> {
  const { data } = await apiClient.get(`datamodel/component`, {
    responseType: 'text',
    signal,
    params,
  });
  return data;
}

export async function fetchSystemXSD(): Promise<string> {
  const { data } = await apiClient.get(`datamodel/system`, { responseType: 'text', params });
  return data;
}

export async function validateComponentXML(payload: FormData) {
  const { data } = await apiClient.post(`datamodel/component/validation`, payload, { params });
  return data;
}

export async function validateSystemXML(payload: FormData) {
  const { data } = await apiClient.post(`datamodel/system/validation`, payload, {
    params,
  });
  return data;
}
