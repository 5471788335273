import { WebSocketClient } from 'oep-web-client';

export enum LauncherElement {
  WORKSPACE = 'local_workspace',
  GATEWAY = 'gateway',
}

/**
 * A websocket client for the Simumatik Service launcher service responsible form managing
 * and administering Simumatik Gateway and local server services.
 */
export class ServiceLauncherClient extends WebSocketClient {
  protected send = async (command: string, element?: string): Promise<any> => {
    return super.send(command, { element });
  };

  /**
   * returns the actual status (started, stopped)of the launcher
   */
  getStatus = async () => {
    return this.send('status');
  };

  /**
   * Updates the service launcher service
   * @returns
   */
  updateLauncher = async () => {
    return this.send('update');
  };

  /**
   * Starts a service for the provided element (workspace|gateway)
   * @param element [string] a gateway or workspace
   * @returns
   */
  start = async (element: LauncherElement) => {
    return this.send('start', element);
  };

  /**
   * Stops a service for the provided element (workspace|gateway)
   * @param element
   * @returns
   */
  stop = async (element: LauncherElement) => {
    return this.send('stop', element);
  };

  /**
   * Restart a service for the provided element (workspace|gateway)
   * @param element
   * @returns
   */
  restart = async (element: LauncherElement) => {
    return this.send('restart', element);
  };
}
