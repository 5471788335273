import { message } from 'antd';
import { useState } from 'react';
import { reportError } from 'simumatik-commons';
import { deleteLibrary, fetchLibraries, patchLibrary } from 'services/libraries';
import { ILibrary } from '../../../common';
import useEffectOnce from '../../../hooks/utils/useEffectOnce';
import { LibraryScope } from '../types';

export const useLibraries = (scope: LibraryScope) => {
  const [libraries, setLibraries] = useState<ILibrary[]>([]);
  const [loading, setLoading] = useState(false);

  const getLibraries = async (searchTerm?: string) => {
    setLoading(true);

    try {
      const response = await fetchLibraries(scope, searchTerm);

      setLibraries(sortLibrariesAlphabetically(response));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
      reportError({
        message: error.message,
        url: error.name,
        at: 'useSystems.fetchSystems()',
      });
    }
  };

  const search = (query: string) => {
    getLibraries(query);
  };

  const add = (newLibrary: ILibrary) => {
    const newLibraries = sortLibrariesAlphabetically([...libraries, newLibrary]);
    setLibraries([...newLibraries]);
  };

  // Send a DELETE request to remove a library, then update state
  const remove = async (toRemove: ILibrary) => {
    try {
      await deleteLibrary(toRemove.id);

      const newLibraries = libraries.filter((library) => library.id !== toRemove.id);
      setLibraries([...newLibraries]);
      message.success(`Removed library ${toRemove.name}`);
    } catch (error) {
      message.error(error.message);
    }
  };

  // Send a PATCH request to change the name of a library, then update state
  const rename = async (library: ILibrary, name: string) => {
    const showSuccessMessage = () => message.success('Renamed library');
    const showErrorMessage = (error: Error) => message.success(error.message);

    const newLibraries = await patch(
      library,
      { name },
      libraries,
      showSuccessMessage,
      showErrorMessage,
    );
    if (!newLibraries) return;
    setLibraries([...sortLibrariesAlphabetically(newLibraries)]);
  };

  const setPublic = async (library: ILibrary, toggle: boolean) => {
    const newLibraries = await patch(library, { public: toggle }, libraries);
    if (!newLibraries) return;
    setLibraries([...newLibraries]);
  };

  const setVisible = async (library: ILibrary, toggle: boolean) => {
    const newLibraries = await patch(library, { visible: toggle }, libraries);
    if (!newLibraries) return;
    setLibraries([...newLibraries]);
  };

  // When the search scope (private/public) is changed, we need to re-fetch the libraries
  // useUpdateEffect(() => {
  //     fetchLibraries()
  // }, [scope])

  useEffectOnce(() => {
    getLibraries();
  });

  return {
    libraries,
    loading,
    search,
    add,
    remove,
    rename,
    setPublic,
    setVisible,
  };
};

function sortLibrariesAlphabetically(library: ILibrary[]) {
  return library.sort((a, b) =>
    a.name.localeCompare(b.name, 'en', {
      ignorePunctuation: true,
      sensitivity: 'base',
    }),
  );
}

async function patch(
  library: ILibrary,
  patchData: Partial<ILibrary>,
  libraries: ILibrary[],
  onSuccess?: Function,
  onError?: Function,
) {
  try {
    const patched = await patchLibrary(library.id, patchData);

    // Replace the renamed library in state
    const index = libraries.findIndex((lib) => lib.id === patched.id);
    const patchedLibraries = libraries;
    patchedLibraries.splice(index, 1, patched);
    if (onSuccess) onSuccess();
    return patchedLibraries;
  } catch (error) {
    if (onError) onError(error);
    return undefined;
  }
}

export default useLibraries;
