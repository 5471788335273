import { Switch, Tooltip } from 'antd';
import { LibraryBrowserContext } from 'features/LibraryBrowser/LibraryBrowserProvider';
import { AppContext } from 'providers/application-context';
import { useContext, useEffect, useState } from 'react';
import { ListItemContext } from '../ListItemProvider';

function TogglePublic() {
  const { me } = useContext(AppContext);
  const { setPublic } = useContext(LibraryBrowserContext).libraryActions;
  const { library } = useContext(ListItemContext);
  const [value, setValue] = useState<boolean>();

  const handleChange = async (checked: boolean) => {
    setPublic(library, checked);
  };

  useEffect(() => {
    setValue(library.public);
  }, [library]);

  return (
    <Tooltip
      title={
        !me.organization?.is_admin
          ? ''
          : 'Toggle library visibility to users outside this organization'
      }
    >
      <Switch
        checked={value}
        checkedChildren="Public"
        unCheckedChildren="Private"
        onChange={handleChange}
        disabled={!me.organization?.is_admin}
        onClick={(_, e) => e.stopPropagation()}
      />
    </Tooltip>
  );
}

export default TogglePublic;
