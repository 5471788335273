import { Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { activateSubscription } from 'services/subscription';

interface IRenewSubscriptionProps {
  organization: any;
  open: boolean;
  onClose: Function;
}

export default function RenewSubscription(props: IRenewSubscriptionProps) {
  const { onClose, organization, open } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [subscriptionKeyMessage, setSubscriptionKeyMessage] = useState<string | undefined>(
    undefined,
  );

  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: any) => activateSubscription(data.subscriptionKey, data.organizationID),
    {
      onSuccess: () => {
        queryClient.fetchQuery(['organization', organization?.id, 'subscription']);
        queryClient.fetchQuery(['organization', organization?.id]);
        close();
      },
      onError: async (error) => {
        setSubscriptionKeyMessage(String(error));
        form.validateFields();
      },
    },
  );

  const close = () => {
    setModalVisible(false);
    form.resetFields();
    mutation.reset();
    onClose();
  };

  const handleOk = () => {
    form.validateFields().then(() => {
      const subscriptionKey = form.getFieldValue('subscription_key');
      mutation.mutate({ subscriptionKey, organizationID: organization.id });
    });
  };

  useEffect(() => {
    setModalVisible(open);
  }, [open]);

  return (
    <Modal
      title={`Renew Subscription for ${organization.name}`}
      open={modalVisible}
      okText={mutation.isLoading ? 'Activating...' : 'Activate'}
      onOk={handleOk}
      onCancel={close}
      destroyOnClose
      confirmLoading={mutation.isLoading}
      closable
    >
      <Form form={form} layout="vertical" scrollToFirstError onFinish={close} requiredMark={false}>
        <Form.Item
          label="Subscription Key"
          name="subscription_key"
          rules={[
            {
              required: true,
              message: 'Please enter a subscription key',
            },
            {
              message: 'Please enter a valid subscription key',
              pattern: /^[A-Za-z0-9]{8}-([A-Za-z0-9]{4}-){3}[A-Za-z0-9]{12}$/,
            },
            () => ({
              validator() {
                if (subscriptionKeyMessage !== undefined)
                  return Promise.reject(new Error(subscriptionKeyMessage));
                return Promise.resolve();
              },
            }),
          ]}
          onReset={() => setSubscriptionKeyMessage(undefined)}
        >
          <Input
            placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
            allowClear
            autoFocus
            onChange={() => {
              if (subscriptionKeyMessage !== undefined) setSubscriptionKeyMessage(undefined);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
