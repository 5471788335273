import AvatarUtils from './avatarUtils';

interface AvatarImageProps {
  initials?: string;
  wrapper?: string;
  string: string;
  fontScale: number;
  width?: number;
  autoColor: boolean;
  textShadow?: boolean;
  roundShape?: boolean;
}

export default function Avatar({
  initials = '',
  wrapper,
  string,
  fontScale,
  width,
  autoColor,
  textShadow,
  roundShape,
}: AvatarImageProps) {
  const imgData = AvatarUtils.generateAvatarImage(
    string ? AvatarUtils.getInitialsFromString(string).toLocaleUpperCase() : initials, // TODO: .toLocaleUpperCase() forceUpperCase = {true};
    {
      fontScale,
      width,
      autoColor,
      textShadow,
    },
  );

  const stringImageStyles = AvatarUtils.getStringImageStyles({
    width,
    roundShape,
  });

  const stringWrapperStyles = AvatarUtils.getStringWrapperStyles({
    width,
    roundShape,
  });

  // const AvatarImage = ({ data }) => <img alt="Avatar image" src={`data:image/jpeg;base64,${data}`} />

  if (wrapper && imgData) {
    return (
      <div className="avatar-wrapper" style={stringWrapperStyles}>
        <img alt="Avatar" src={`${imgData}`} style={stringImageStyles} />
      </div>
    );
  }

  return <img alt="Avatar" src={`${imgData}`} style={stringImageStyles} />;
}
