import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { IComponent } from 'types/api-interfaces';
import { ComponentLibraryBrowserContext } from 'providers/ComponentLibraryBrowserProvider';
import { useContext } from 'react';

function RemoveButton(props: { component: IComponent }) {
  const { component } = props;
  const { selectedLibrary } = useContext(ComponentLibraryBrowserContext);

  const handleConfirm = (e: any) => {
    e.stopPropagation();
    selectedLibrary.remove(component);
  };

  return (
    <Popconfirm
      title="Remove component from this library?"
      onConfirm={handleConfirm}
      okText="Yes"
      cancelText="No"
    >
      <Button type="link" danger icon={<DeleteOutlined />} onClick={(e) => e.stopPropagation()} />
    </Popconfirm>
  );
}

export default RemoveButton;
