import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Space } from 'antd';
import useUpdateEffect from 'hooks/utils/useUpdateEffect';
import { useContext, useMemo } from 'react';
import { NodeValueInputContext } from '../NodeValueInputProvider';
import useNodeValueInput from '../hooks/useNodeValueInput';

type TInputVectorProps = { value?: number[]; onChange?: Function; labels?: string[] };

function InputVector({ value, onChange, labels }: TInputVectorProps) {
  const { object, node } = useContext(NodeValueInputContext);
  const { handleChange } = useNodeValueInput(object?.value);

  const [form] = Form.useForm();

  const initialValues: number[] = useMemo(() => {
    // Value prop exists to make the component compatible with AntD forms
    if (value) return value;
    else if (object) return object.value;

    return [];
  }, [object, value]);

  const submit = () => {
    const values = form.getFieldsValue();
    const valuesArray = Object.values(values) as number[][];
    const valuesString = valuesArray[0].join(' ');
    if (onChange) onChange(valuesString);
    else handleChange(valuesString);
  };

  useUpdateEffect(() => {
    form.resetFields();
  }, [node]);

  return (
    <Form form={form} initialValues={{ list: initialValues }} layout="vertical">
      <Form.Item label="Vector Indices">
        <Form.List name={['list']}>
          {(subFields, subOpt) => (
            <div className="flex flex-col gap-2">
              {subFields.map((subField) => (
                <Space key={subField.key}>
                  <Form.Item name={[subField.name]} initialValue={0} noStyle>
                    <InputNumber
                      step={0.01}
                      addonBefore={subField.name + 1}
                      onPressEnter={submit}
                      onBlur={submit}
                      onStep={submit}
                    />
                  </Form.Item>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      subOpt.remove(subField.name);
                      submit();
                    }}
                    type="text"
                    danger
                  />
                </Space>
              ))}
              <Button
                type="dashed"
                onClick={() => {
                  subOpt.add(0);
                  submit();
                }}
                icon={<PlusOutlined />}
                block
              >
                Add Index
              </Button>
            </div>
          )}
        </Form.List>
      </Form.Item>
    </Form>
  );
}

InputVector.defaultProps = {
  value: undefined,
  onChange: undefined,
  labels: undefined,
};

export default InputVector;
