import { Button, Col, Image, Modal, Row, Typography } from 'antd';
import { IComponent, ISystem } from 'types/api-interfaces';
import capitalizeString from 'utils/capitalizeString';
import { thumbnailPlaceholderUrl } from 'utils/environmentVariables';
import styles from './InfoModal.module.css';

const { Title, Paragraph } = Typography;

type ObjectInfoModalProps = {
  object: ISystem | IComponent;
  open: boolean;
  onClose: () => void;
  onClickOpen?: () => void;
  onClickLoad?: () => void;
};

function ObjectInfoModal({
  object,
  open,
  onClose,
  onClickOpen,
  onClickLoad,
}: ObjectInfoModalProps) {
  const modifiedDate = new Date(object.modified);
  const isBlocked = object.cloning_blocked;

  return (
    <Modal
      title={`${capitalizeString(object.type as string)} Information`}
      open={open}
      onCancel={onClose}
      width="50%"
      destroyOnClose
      footer={
        <div className={styles.footer}>
          <span>Last Modified: {modifiedDate.toLocaleString()}</span>
          <Button.Group>
            <Button onClick={onClose}>Cancel</Button>
            {onClickOpen ? (
              <Button
                onClick={onClickOpen}
                disabled={object.type === 'component' && isBlocked}
                type={onClickLoad ? 'default' : 'primary'}
                title={isBlocked ? `The ${object.type} page is blocked` : undefined}
              >
                Open
              </Button>
            ) : null}
            {onClickLoad ? (
              <Button type="primary" onClick={onClickLoad}>
                Load
              </Button>
            ) : null}
          </Button.Group>
        </div>
      }
    >
      <Row gutter={24}>
        <Col span={14}>
          <Title level={5}>{object.name}</Title>
          {object.description.split('\n').map((paragraph) => (
            <Paragraph key={paragraph}>{paragraph}</Paragraph>
          ))}
        </Col>
        <Col span={10}>
          <Image src={object.thumbnail_url || 'error'} fallback={thumbnailPlaceholderUrl} />
        </Col>
      </Row>
    </Modal>
  );
}

export default ObjectInfoModal;
