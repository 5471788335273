import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { AppContext } from 'providers/application-context';
import { useContext, useState } from 'react';
import { ILibrary } from 'types/api-interfaces';
import { canModify } from 'utils/authorizations';
import RenameModal from './RenameModal';

function Rename(props: { library: ILibrary }) {
  const { library } = props;
  const { me } = useContext(AppContext);
  const canIModify = canModify(me, library);
  const [showModal, setShowModal] = useState(false);

  const handleClick = async (e: any) => {
    e.stopPropagation();
    if (!canIModify) return;

    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleFinish = () => {
    setShowModal(false);
  };

  return (
    <>
      <Tooltip placement="top" title="Rename">
        <Button icon={<EditOutlined />} type="link" onClick={handleClick} disabled={!canIModify} />
      </Tooltip>
      <RenameModal
        library={library}
        open={showModal}
        onCancel={handleCancel}
        onFinish={handleFinish}
      />
    </>
  );
}

export default Rename;
