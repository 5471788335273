import { Provider } from '@rollbar/react';
import { ReactNode } from 'react';
import Rollbar from 'rollbar';
import { ErrorReporting } from 'simumatik-commons';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIp: 'anonymize',
  environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT,
  payload: {
    client: {
      javascript: {
        code_version: import.meta.env.VITE_VERSION,
        source_map_enabled: true,
      },
    },
  },
};

const rollbar = new Rollbar(rollbarConfig);

ErrorReporting.rollbar = rollbar;

function RollbarProvider(props: { children: ReactNode }) {
  const { children } = props;

  return <Provider instance={rollbar}>{children}</Provider>;
}

export default RollbarProvider;
