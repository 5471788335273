import { Tooltip } from 'antd';
import React, { useMemo, useRef } from 'react';
import styles from './PortDot.module.css';

interface IPortDotProps {
  coordinate: number[];
  element: Element;
  name: string;
  index: number;
  onUpdate: Function;
  editable: boolean;
  parentSize: { x: number; y: number };
}

type LineConfig = {
  orientation: 'vertical' | 'horizontal';
  top: number;
  left: number;
};

export default function PortDot({
  coordinate: [left, top],
  editable,
  element,
  index,
  name,
  onUpdate,
  parentSize,
}: IPortDotProps) {
  const startX = useRef<number>(0);
  const startY = useRef<number>(0);

  const lineState = useMemo<LineConfig>(() => {
    const result = [top, parentSize.x - left, parentSize.y - top, left].reduce(
      (minimum: { index: number; value: number }, value: number, index: number) => {
        if (value <= minimum.value || minimum.value === -1) {
          return { index, value };
        }
        return minimum;
      },
      { index: -1, value: -1 },
    );

    switch (Math.abs(result.index % 4)) {
      case 0:
        return { orientation: 'vertical', top: -45, left: -2 };
      case 1:
        return { orientation: 'horizontal', top: -22, left: 0 };
      case 2:
        return { orientation: 'vertical', top: -16, left: -2 };
      case 3:
      default:
        return { orientation: 'horizontal', top: -22, left: -26 };
    }
  }, [parentSize, left, top]);

  const handleDragStart: React.DragEventHandler<HTMLSpanElement> = (event) => {
    if (!editable) return;
    startX.current = event.screenX;
    startY.current = event.screenY;
  };

  const handleDragEnd: React.DragEventHandler<HTMLSpanElement> = (event) => {
    if (!editable) return;
    event.preventDefault();

    const target = event.target as HTMLSpanElement;

    if (target) {
      const dx = event.screenX > 0 ? event.screenX - startX.current : 0;
      const dy = event.screenY > 0 ? event.screenY - startY.current : 0;

      const x = Number(target.style.left.replace('px', '')) + dx;
      const y = Number(target.style.top.replace('px', '')) + dy;
      element.textContent = `${x} ${y}`;
      onUpdate();
    }
  };

  return (
    <span
      key={index}
      style={{
        top,
        left,
      }}
      className={styles.container}
      draggable={editable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Tooltip title={name}>
        <span className={styles.dot} />
      </Tooltip>
      <div
        style={{
          position: 'relative',
          top: lineState.top,
          left: lineState.left,
          height: lineState.orientation === 'vertical' ? 24 : 4,
          width: lineState.orientation === 'vertical' ? 4 : 24,
          background: 'rgb(0, 0, 0)',
          transform: 'rotate(180deg)',
        }}
      />
    </span>
  );
}
