import { LibraryBrowserContext } from 'features/LibraryBrowser/LibraryBrowserProvider';
import { useContext } from 'react';
import { ListItemContext } from '../../ListItemProvider';

const useActionsMenu = () => {
  const { library } = useContext(ListItemContext);
  const { remove, setPublic, setVisible } = useContext(LibraryBrowserContext).libraryActions;

  const handleRemove = (e: any) => {
    e.domEvent.stopPropagation();
    remove(library);
  };

  const handleTogglePublic = (e: any) => {
    e.domEvent.stopPropagation();
    setPublic(library, !library.public);
  };

  const handleToggleVisibility = (e: any) => {
    e.domEvent.stopPropagation();
    setVisible(library, !library.visible);
  };

  return [handleRemove, handleTogglePublic, handleToggleVisibility];
};

export default useActionsMenu;
