import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { AppContext } from 'providers/application-context';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { useContext, useMemo, useState } from 'react';
import { ListItemContext } from '../ListItemProvider';
import RenameModal from './RenameModal';
import useActionsMenu from './hooks/useActionsMenu';

function ActionsMenu() {
  const { me } = useContext(AppContext);
  const { library } = useContext(ListItemContext);
  const [handleRemove, handleTogglePublic, handleToggleVisibility] = useActionsMenu();
  const [showRenameModal, setShowRenameModal] = useState(false);

  const handleRenameClick: MenuClickEventHandler = (e) => {
    e.domEvent.stopPropagation();
    setShowRenameModal(true);
  };

  const handleRenameCancel = () => {
    setShowRenameModal(false);
  };

  const handleRenameFinish = () => {
    setShowRenameModal(false);
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  const organizationOption: MenuProps['items'] = useMemo(() => {
    if (me.organization) {
      return [
        {
          onClick: handleTogglePublic,
          label: library.public ? 'Make Private' : 'Make Public',
          key: 'public',
        },
        {
          onClick: handleToggleVisibility,
          label: library.visible ? 'Make Hidden' : 'Make Visible',
          key: 'visibility',
        },
        {
          type: 'divider',
        },
      ];
    }

    return [];
  }, [
    handleTogglePublic,
    handleToggleVisibility,
    library.public,
    library.visible,
    me.organization,
  ]);

  const items: ItemType[] = [
    ...organizationOption,
    {
      onClick: handleRenameClick,
      icon: <EditOutlined />,
      label: 'Rename',
      key: 'rename',
    },
    {
      type: 'divider',
    },
    {
      onClick: handleRemove,
      danger: true,
      icon: <DeleteOutlined />,
      label: 'Remove',
      key: 'remove',
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button onClick={handleClick} icon={<EllipsisOutlined />} type="text" />
      </Dropdown>
      <RenameModal
        library={library}
        open={showRenameModal}
        onCancel={handleRenameCancel}
        onFinish={handleRenameFinish}
      />
    </>
  );
}

export default ActionsMenu;
