import Avatar from 'lib/react-string-avatar';

function UserAvatar(props: { userName: string; size?: number }) {
  const { userName, size } = props;

  return <Avatar string={userName} width={size} fontScale={80} autoColor roundShape />;
}

UserAvatar.defaultProps = {
  size: 32,
};

export default UserAvatar;
