import { App } from 'antd';
import { IComponent, ILibrary } from 'types/api-interfaces';
import useUpdateEffect from 'hooks/utils/useUpdateEffect';
import { useState } from 'react';
import { addToLibrary, deleteLibraryComponent, fetchLibrary } from 'services/libraries';

const useSelectedLibrary = () => {
  const [library, setLibrary] = useState<ILibrary | undefined>();
  const [components, setComponents] = useState<IComponent[]>([]);
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();

  const fetchComponents = async () => {
    if (!library) return;
    try {
      setLoading(true);
      const response = await fetchLibrary(library.id);

      setComponents(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const add = async (toAdd: IComponent) => {
    if (components.find((component) => component.id === toAdd.id)) {
      message.warning('This component has already been added to this library');
      return;
    }

    if (!library) return;

    try {
      await addToLibrary(library.id, toAdd);

      setComponents(sortComponentsAlphabetically([...components, toAdd]));
    } catch (error) {
      message.error(error.message);
    }
  };

  const remove = async (toRemove: IComponent) => {
    if (!library) return;
    try {
      await deleteLibraryComponent(library?.id, toRemove.id);

      const newComponents = components.filter((component) => component.id !== toRemove.id);
      setComponents(newComponents);
      message.success(`Removed ${toRemove.name} from library`);
    } catch (error) {
      message.error(error.message);
    }
  };

  const clear = () => {
    setComponents([]);
    setLibrary(undefined);
  };

  useUpdateEffect(() => {
    if (!library) return;
    fetchComponents();
  }, [library]);

  return {
    library,
    setLibrary,
    components,
    loading,
    add,
    remove,
    clear,
  };
};

export default useSelectedLibrary;

function sortComponentsAlphabetically(components: IComponent[]) {
  return components.sort((a, b) =>
    a.name.localeCompare(b.name, 'en', {
      ignorePunctuation: true,
      sensitivity: 'base',
    }),
  );
}
