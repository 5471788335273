import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type TError = {
  level: string;
  message: string;
  line?: number;
};

type State = {
  errors: TError[];
};

type Actions = {
  updateErrors: (errors: TError[]) => void;
};

const initialState: State = {
  errors: [],
};

export const useXMLErrorsStore = create(
  devtools<State & Actions>(
    (set) => ({
      ...initialState,
      updateErrors: (errors) => {
        set({
          errors,
        });
      },
    }),
    { name: 'XMLErrors', enabled: import.meta.env.DEV },
  ),
);

export default useXMLErrorsStore;
