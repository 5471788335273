import { WebSocketClient } from 'oep-web-client';

export enum GatewayStatus {
  STANDBY = 'standby',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  ERROR = 'error',
  RESET = 'reset', // we treat is same as standby?
  NONE = 'unavailable',
}

type GatewayResponse = {
  status: GatewayStatus;
  message: string | undefined;
  ip: string | undefined;
};

export class GatewayClient extends WebSocketClient {
  get url() {
    return this.client.url;
  }

  protected send = async (command: string, data?: object): Promise<any> => {
    return super.send(command, { data });
  };

  /** Connects a gateway to a server with a given serverIPAdress */
  connect = async (serverIPAddress: string): Promise<GatewayResponse> => {
    return this.send('connect', { url: serverIPAddress });
  };

  /**
   * Disconnect the gateway from OEP server as an OPCUA client
   */
  disconnect = async (): Promise<GatewayResponse> => {
    return this.send('disconnect');
  };

  /**
   * Reset the gateway, user to recover from error.
   */
  reset = async (): Promise<GatewayResponse> => {
    return this.send('reset');
  };

  /**
   * Return the current status of the gateway.
   * @returns
   */
  status = async (): Promise<GatewayResponse> => {
    return this.send('status');
  };

  /**
   * Returns the version of the gateway.
   */
  version = async (): Promise<GatewayResponse> => {
    return this.send('version');
  };
}
