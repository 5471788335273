import AvatarConstants from './avatarConstants';

interface AvatarImageProps {
  fontScale?: number;
  fontWeight?: number;
  pictureResolution?: number;
  width?: number;
  bgColor?: string;
  autoColor: boolean;
  textShadow?: boolean;
  textColor?: string;
  pictureFormat?: string;
}

interface StringImageStylesProps {
  width?: number;
  roundShape?: boolean;
  cornerRadius?: number;
  pixelated?: boolean;
}

interface StringWrapperStyleProps {
  width?: number;
  roundShape?: boolean;
  cornerRadius?: number;
}

const AvatarUtils = {
  getInitialsFromString: (str: string) => {
    if (!str) {
      return '';
    }

    // eslint-disable-next-line no-param-reassign
    const strArray = str.split(' ');
    let output = '';
    let i = 0;
    const len = strArray.length;

    for (i; i < len; i += 1) {
      if (strArray[i] !== '') {
        output += strArray[i][0];
      }
    }
    return output;
  },

  generateAvatarImage(str: string, props: AvatarImageProps) {
    if (!str) {
      // eslint-disable-next-line no-param-reassign
      str = '';
    }

    let WIDTH = 256;
    let HEIGHT = 256;
    let canvas = null;
    let ctx = null;
    let fontSize = 12;
    const fontScale = props.fontScale ? props.fontScale : 100;
    const fontWeight = props.fontWeight ? props.fontWeight : 300;
    const defaultTextColor = '#fff';
    let font = '300 12px sans-serif';

    if (props.pictureResolution && props.pictureResolution > 0) {
      // Use by default the desired resolution to create the picture of the avatar
      WIDTH = props.pictureResolution;
      HEIGHT = props.pictureResolution;
    } else if (props.width) {
      // If resolution was not provided use width param
      WIDTH = props.width;
      HEIGHT = props.width;
    } else {
      // If none of them were provided use default width of 45
      WIDTH = AvatarConstants.DEFAULT_WIDTH;
      HEIGHT = AvatarConstants.DEFAULT_WIDTH;
    }

    canvas = document.createElement('canvas');
    canvas.id = `ajsr-avatar-${Date.now()}`;
    canvas.width = WIDTH;
    canvas.height = HEIGHT;

    ctx = canvas.getContext('2d');
    // ctx.fillStyle = props.bgColor;

    if (!ctx) return false;

    // Calculate color
    ctx.fillStyle = props.bgColor ? props.bgColor : '#000';

    if (props.autoColor === true) {
      const lon = str.length;
      let charIndex = 0;
      for (let i = 0; i < lon; i += 1) {
        charIndex = str.charCodeAt(i);
      }
      const colorIndex = charIndex % AvatarConstants.colorsPalette.length;
      ctx.fillStyle = AvatarConstants.colorsPalette[colorIndex];
    }

    ctx.fillRect(0, 0, WIDTH, HEIGHT);

    fontSize = WIDTH / (2 / (fontScale / 100));
    // font = fontWeight + ' ' + fontSize + 'px sans-serif';
    font = `${fontWeight} ${fontSize}px sans-serif`;
    ctx.font = font;

    if (props.textShadow === true) {
      ctx.shadowColor = 'black';
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      ctx.shadowBlur = 5;
    }

    ctx.textAlign = 'center';
    ctx.fillStyle = props.textColor ? props.textColor : defaultTextColor;
    ctx.fillText(str, WIDTH / 2, HEIGHT - HEIGHT / 2 + fontSize / 3);

    return canvas.toDataURL(`image/${props.pictureFormat}`);
  },

  getStringImageStyles: (props: StringImageStylesProps) => {
    let w = null;

    // 1 - width and height

    if (!props.width) {
      w = AvatarConstants.DEFAULT_WIDTH;
    } else {
      w = props.width;
    }

    const resultObj: {
      width: string;
      height: string;
      borderRadius?: string;
      // imageRendering?: string;
    } = {
      width: `${w}px`,
      height: `${w}px`,
    };

    // 2 - round shape

    if (props.roundShape) {
      resultObj.borderRadius = `${w}px`;
    } else if (props.cornerRadius) {
      resultObj.borderRadius = `${props.cornerRadius}px`;
    }

    // 3 - pixelated

    if (props.pixelated === true) {
      // resultObj.imageRendering = 'pixelated';
      // resultObj.imageRendering = "-moz-crisp-edges";
    }

    return resultObj;
  },

  getStringWrapperStyles: (props: StringWrapperStyleProps) => {
    let w = null;

    // 1 - width and height

    if (!props.width) {
      w = AvatarConstants.DEFAULT_WIDTH;
    } else {
      w = props.width;
    }

    const resultObj: {
      width: string;
      height: string;
      display: string;
      // boxSizing: BoxSizing | undefined;
      borderRadius?: string;
    } = { width: `${w}px`, height: `${w}px`, display: 'inline-block' };

    // 2 - round shape

    if (props.roundShape) {
      resultObj.borderRadius = `${w}px`;
    } else if (props.cornerRadius) {
      resultObj.borderRadius = `${props.cornerRadius}px`;
    }

    return resultObj;
  },
};

export default AvatarUtils;
