import { ISystem } from 'types/api-interfaces';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type State = {
  selectedSystems: ISystem[];
  systemSort: string;
  sortDirection: string;
};

type Actions = {
  setSelectedSystems: (systems: ISystem[]) => void;
  reset: () => void;
  addSystemSelection: (system: ISystem) => void;
  removeSystemSelection: (system: ISystem) => void;
  setSystemSort: (sort: string) => void;
  setSortDirection: (sort: string) => void;
};

const initialState: State = {
  selectedSystems: [],
  systemSort: 'name',
  sortDirection: 'ascending',
};

const useSystemBrowserStore = create(
  devtools<State & Actions>(
    (set, get) => ({
      ...initialState,
      setSelectedSystems: (selectedSystems) => set({ selectedSystems }),
      reset: () => set({ selectedSystems: [] }),
      addSystemSelection: (system) => set({ selectedSystems: [...get().selectedSystems, system] }),
      removeSystemSelection: (system) =>
        set({ selectedSystems: [...get().selectedSystems.filter(({ id }) => id !== system.id)] }),
      setSystemSort: (systemSort) => set({ systemSort }),
      setSortDirection: (sortDirection) => set({ sortDirection }),
    }),
    { name: 'Systems Browser', enabled: import.meta.env.DEV },
  ),
);

export default useSystemBrowserStore;
