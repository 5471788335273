import useAnalytics from 'hooks/useAnalytics';
import useInterval from 'hooks/utils/useInterval';
import { OEPClientInst } from 'oep-web-client';
import { useEffect, useRef, useState } from 'react';
import NewWindow from 'react-new-window';
import { useEffectOnce } from 'usehooks-ts';
import { Logo } from './Logo';
import { Statistics } from './Statistics';

export const DevToolsButton = () => {
  const [open, setOpen] = useState(false);
  const [connected, setConnected] = useState(false);
  const [devWindow, setDevWindow] = useState<Window | null>(null);

  const [height, setHeight] = useState(800);
  const [width, setWidth] = useState(1200);
  const screenX = useRef(0);
  const screenY = useRef(0);

  const ref = useRef<NewWindow>(null);

  const { trackEvent } = useAnalytics();

  useInterval(
    () => {
      if (OEPClientInst.Instance?.isOpen) setConnected(true);
    },
    !connected ? 1000 : null,
  );

  const handleUnload = () => {
    setOpen(false);
    setDevWindow(null);
  };

  useEffect(() => {
    if (devWindow) {
      devWindow.onresize = (e) => {
        const target = e.target as Window;
        setHeight(target.outerHeight);
        setWidth(target.outerWidth);
      };

      const checkScreenPos = () => {
        if (devWindow.screenX !== screenX.current) screenX.current = devWindow.screenX;
        if (devWindow.screenY !== screenY.current) screenY.current = devWindow.screenY;

        devWindow.requestAnimationFrame(checkScreenPos);
      };

      devWindow.requestAnimationFrame(checkScreenPos);
    }
  }, [devWindow]);

  useEffectOnce(() => {
    return () => {
      ref.current?.release();
    };
  });

  return (
    <div className="fixed bottom-0 right-0 z-[9999999] p-2">
      <button
        title="Dev Tools"
        className="hover:rotate-720 aspect-square w-14 cursor-pointer border-none bg-transparent transition duration-500 ease-in-out hover:hue-rotate-180"
        onClick={() => {
          if (!open) trackEvent('Dev Tools');
          setOpen(!open);
        }}
      >
        <Logo className="shadow-black drop-shadow-lg" />
      </button>
      {open ? (
        <NewWindow
          center="parent"
          title="Simumatik Dev Tools"
          name="Simumatik Dev Tools"
          ref={ref}
          onUnload={handleUnload}
          onOpen={(window) => {
            setDevWindow(window);
            window.moveTo(screenX.current, screenY.current);
          }}
          features={{
            width,
            height,
          }}
        >
          <div className="box-border h-full w-full overflow-auto bg-neutral-900 font-sans text-white">
            {connected ? <Statistics /> : <div>Waiting for connection</div>}
          </div>
        </NewWindow>
      ) : null}
    </div>
  );
};
