import { PlusOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { IComponent } from 'types/api-interfaces';
import { ComponentLibraryBrowserContext } from 'providers/ComponentLibraryBrowserProvider';
import { useContext } from 'react';

interface IProps {
  component: IComponent;
}

function AddToLibrary({ component }: IProps) {
  const { selectedLibrary } = useContext(ComponentLibraryBrowserContext);

  const handleClick = (e: any) => {
    e.stopPropagation();
    selectedLibrary.add(component);
  };

  return (
    <Tooltip title="Add to library">
      <Button onClick={handleClick} icon={<PlusOutlined />} shape="circle" />
    </Tooltip>
  );
}

export default AddToLibrary;
