import Rollbar from 'rollbar';
import { xsdToSMTK } from 'simumatik-commons';
import { IAttribInfo, IXMLNodeInfo } from 'features/ComponentPage/ComponentEditor/interfaces';

const getTypeRestrictions = (typeName: string, xsdRoot: Element | Document): any => {
  const extension = xsdRoot.querySelector(`complexType[name=${typeName}] extension`);
  const list = xsdRoot.querySelector(`simpleType[name=${typeName}] list`);

  if (extension) {
    return getTypeRestrictions(String(extension.getAttribute('base')), xsdRoot);
  }
  if (list && !list.querySelector('simpleType')) {
    // if( list)

    return getTypeRestrictions(String(list.getAttribute('itemType')), xsdRoot);
  }
  // a simple type

  const pattern = xsdRoot.querySelector(`simpleType[name=${typeName}] restriction > pattern`);
  const min = xsdRoot.querySelector(`simpleType[name=${typeName}] restriction > minInclusive`);
  const max = xsdRoot.querySelector(`simpleType[name=${typeName}] restriction > maxInclusive`);
  const length = xsdRoot.querySelector(`simpleType[name=${typeName}] restriction > length`);
  const enumeration = xsdRoot.querySelectorAll(
    `simpleType[name=${typeName}] restriction  enumeration`,
  );

  return {
    pattern:
      pattern && pattern.getAttribute('value')
        ? new RegExp(String(pattern.getAttribute('value')))
        : null,
    min: min && min.getAttribute('value') ? Number(min.getAttribute('value')) : null,
    max: max && max.getAttribute('value') ? Number(max.getAttribute('value')) : null,
    length: length && length.getAttribute('value') ? Number(length.getAttribute('value')) : null,
    enumeration: [...enumeration].map((element: Element) => element.getAttribute('value')),
  };
};

export const getSMTKTypeOptions = (nodeType: string, dataType: string, xsdRoot: Document) => {
  // escape system types: they don't exist in component xsd: Temp fix
  if (
    !xsdRoot ||
    [
      'emulation_step',
      'global_erp',
      'global_cfm',
      'constraint_iterations',
      'gateway_interval',
      'time_relation',
    ].includes(nodeType)
  ) {
    return undefined;
  }

  const appInfo = xsdRoot.querySelector(
    `element[name=${nodeType}][type=${dataType}] annotation>appinfo`,
  );

  let labels = [];
  try {
    if (appInfo && appInfo.textContent) {
      const labelsString = JSON.parse(appInfo.textContent).labels;
      if (labelsString) labels = labelsString.split(' ');
    }
  } catch (error) {
    reportError({
      message: error.message,
      url: error.name,
      data: `nodeType ${nodeType} dataType ${dataType}`,
      at: 'tree-value-editor.getSMTKTypeOptions()',
    });
  }
  return { ...getTypeRestrictions(dataType, xsdRoot), labels };
};

export const nodeInfo = (
  xmlElement: Element | undefined,
  xsdDoc: Document | undefined,
  rollbar?: Rollbar,
): IXMLNodeInfo => {
  const nodeInfo = {} as IXMLNodeInfo;
  nodeInfo.attributes = [];

  if (!xmlElement) return nodeInfo;
  if (!xsdDoc) return nodeInfo;

  [...xmlElement.attributes].forEach((attribute: Attr) => {
    const attribInfo = {} as IAttribInfo;
    attribInfo.attribute = attribute;
    attribInfo.smtkObject = xsdToSMTK(
      attribute.localName === 'public' ? 'smtk_bool' : 'smtk_name',
      attribute.value,
    );

    // get choices
    attribInfo.enumeration = undefined;
    if (attribute.ownerElement && attribute.ownerElement.localName === 'variable') {
      const e = new Map<string, string>();
      [
        ...xsdDoc.querySelectorAll(
          `complexType[name="smtk_var"] attribute[name=${attribute.localName}] enumeration`,
        ),
      ].forEach((element: Element) =>
        e.set(`${element.getAttribute('value')}`, `${element.getAttribute('value')}`),
      );

      if (e.size !== 0) attribInfo.enumeration = e;
    }

    nodeInfo.attributes.push(attribInfo);
  });

  nodeInfo.name = xmlElement.tagName;
  nodeInfo.xmlElement = xmlElement;

  const xsdRoot = xsdDoc.firstElementChild;
  if (!xsdRoot) throw new Error('Schema file not initialized!');

  const complexType = xsdRoot.querySelector(`complexType[name=${xmlElement.tagName}]`);
  if (complexType && xmlElement.children.length) {
    // && not a variable (specially added for limits duplicate types)
    // isComplextype

    const description = complexType.querySelector('annotation>documentation');

    nodeInfo.description = description ? description.textContent : null;
    nodeInfo.smtkObject = null;
  } else {
    // isSimpleType
    try {
      const parentName = xmlElement.parentElement ? xmlElement.parentElement.localName : '';
      if (!parentName) return nodeInfo;

      const element = xsdRoot.querySelector(
        `complexType[name=${parentName}] element[name=${xmlElement.localName}]`,
      );
      if (!element) return nodeInfo;

      const description = element.querySelector('annotation>documentation');
      nodeInfo.description = description ? description.textContent : null;

      const appInfo = element.querySelector('annotation>appinfo');
      if (appInfo && appInfo.textContent) {
        try {
          const info = JSON.parse(appInfo.textContent);

          nodeInfo.default = info.default;
          nodeInfo.var_type = info.var_type;
          if (info.labels) nodeInfo.labels = String(info.labels).split(' ');
        } catch (error) {
          reportError({
            message: error.message,
            url: error.name,
            data: xmlElement.tagName,
            at: 'tree-value-editor.nodeInfo(1)',
          });
        }
      }

      let typeName = String(element.getAttribute('type') || element.getAttribute('name'));
      if (typeName === 'smtk_var') {
        typeName = `${xmlElement.getAttribute('data_type')}`;
      }

      const value =
        xmlElement.textContent !== undefined ? xmlElement.textContent : nodeInfo.default;
      const options = {
        ...getTypeRestrictions(typeName, xsdRoot),
        labels: nodeInfo.labels,
      };

      const smtkObject = xsdToSMTK(typeName, value, options);
      nodeInfo.smtkObject = smtkObject;
      nodeInfo.typeName = typeName;
    } catch (error) {
      rollbar?.error(error);
      rollbar?.info(error.message, xmlElement.tagName);
    }
  }

  return nodeInfo;
};
