import { Button, Card, Col, Result, Row } from 'antd';
import { useHistory } from 'react-router-dom';

export function UpgradeSuccess() {
  const history = useHistory();

  const handleClick = () => {
    history.replace('/');
    window.location.reload();
  };

  return (
    <Row>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 12, offset: 6 }}>
        <Card style={{ marginTop: '10em' }}>
          <Result
            status="success"
            title="Upgrade Success!"
            subTitle={
              <div style={{ margin: 'auto', maxWidth: '70ch' }}>
                <p>Your subscription plan has been upgraded to Standard.</p>
                <p>
                  You can cancel the subscription at any time by accessing
                  <q>Manage Subscription</q> from the start page.
                </p>
              </div>
            }
            extra={[
              <Button type="primary" key="continue" onClick={handleClick}>
                Back to Start Page
              </Button>,
            ]}
          />
        </Card>
      </Col>
    </Row>
  );
}
