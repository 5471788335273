/**
 * Converts a value to a boolean
 */
export function toBoolean(value: any): boolean {
  if (typeof value === 'string') {
    if (value.toLowerCase() === 'true') {
      return true;
    }
    return false;
  }
  return Boolean(value);
}

/** Convert IP or domain to legitimate websocket server URL for simumatik */
export function toWsURL(ip: string, port?: string) {
  if (ip.endsWith('simumatik.com')) {
    return `wss://${ip}`;
  }
  return `ws://${ip}${port ? `:${port}` : ''}`;
}
