import { NodeName } from 'simumatik-commons';

export enum Plan {
  Basic = 'basic',
  Standard = 'standard',
}

export interface IMe {
  country_code: string;
  created: string;
  credit_balance: number;
  decoded_id: string;
  email: string;
  id: string;
  is_organization: boolean;
  modified: string;
  name: string;
  organization?: IOrganization;
  plan: Plan;
  preferred_region_city: string;
  preferred_region_country: string;
  preferred_region_id: string;
  role: string;
  server_count: number;
  server_limit: number;
  settings: unknown | null;
  shared_servers?: IServer[];
}
export type IMeOptions = Partial<IMe>;

export type AssetTaskType = 'optimization' | 'conversion';

export type AssetTaskStatus =
  | 'requested'
  | 'preparing'
  | 'converting'
  | 'optimizing'
  | 'failed'
  | 'finished';

export type AssetTask = {
  id: string;
  original_asset_id: string | null;
  type: AssetTaskType;
  status: AssetTaskStatus;
  statusMessage: string | null;
  created: string;
  modified: string;
};

export type AssetCategory =
  | 'model'
  | 'script'
  | 'image'
  | 'audio'
  | 'document'
  | 'archive'
  | 'video'
  | 'none';

export interface IAsset {
  name: string;
  id: string;
  file_id: string | null;
  file_size: number;
  mime_type: string;
  belongs_to_object: string;
  category: AssetCategory;
  task?: AssetTask;
}

export interface IAuthorizeable {
  id: string;
  creator_id: string;
  creator_name: string;
  owner_id: string;
  owner_name: string;
  share_mode?: 'viewer' | 'editor';
  can_clone?: boolean;
}

export interface ILibrary extends IAuthorizeable {
  visible: boolean | undefined;
  name: string;
  public: boolean;
}

export interface IID {
  id: string;
}

export interface IObject extends IAuthorizeable {
  file_size: number;
  created: string;
  modified: string;
  download_count: number;
  name: string;
  description: string;
  keywords: string;
  thumbnail_asset_id: string;
  thumbnail_url: string;
  type?: NodeName.System | NodeName.Component;
  has_review_version?: boolean;
  has_public_version?: boolean;
  public?: number;
  nodeId?: any;
  cloning_blocked?: boolean;
  modified_by?: string;
  modifier_name?: string;
  published?: string;
  published_by?: string;
  publisher_name?: string;
}

export interface IComponent extends IObject {
  manufacturer: string;
  model: string;
  e_class: string;
}

export interface ISystem extends IObject {
  components: IID[];
}

export interface IRegion {
  id: string;
  country_code: string;
  country: string;
  city: string;
  preferred_by_user: boolean;
  closest_to_user_ip: boolean;
}

export enum ServerStatus {
  Starting = 'starting',
  Started = 'started',
  Running = 'running',
  Stopped = 'stopped',
  Failed = 'failed',
  Stopping = 'stopping',
}
export interface IServer extends IAuthorizeable {
  ip_address: string;
  domain_name: string;
  status: ServerStatus;
  configuration_credits_per_hour?: string;
  configuration_id?: number;
  configuration_name?: string;
  time_to_live_in_seconds?: number;
  created: string;
}
export interface IUser {
  id: string;
  name: string;
}
export interface IOrganization {
  organization_type: 'enterprise' | 'educational';
  logo_url: string;
  id: string;
  name: string;
  organization_is_blocked: boolean;
  is_blocked: boolean;
  is_admin: boolean;
  can_create: boolean;
  can_delete: boolean;
  can_manage: boolean;
  shared_servers: IServer[];
  plan: string;
  credit_limit: number | null;
  credits_available: number;
  credits_used: number;
  total_credits_used: number;
}

export type ObjectTransferStatus = 'accepted' | 'pending';

export interface IObjectTransfer {
  id: string;
  object_name: string;
  object_id: string;
  sender_name: string;
  recipient_name: string;
  object_type: 'component' | 'system';
  status: ObjectTransferStatus;
}

export interface IOrganizationMember {
  can_create: boolean;
  can_delete: boolean;
  can_manage: boolean;
  created: string;
  credit_limit: number | null;
  credits_available: number;
  credits_used: number;
  email: string;
  id: string;
  is_admin: boolean;
  is_blocked: boolean;
  metadata: string;
  modified: string;
  name: string;
  role: UserRole;
  total_credits_used: number;
}

export interface IGroupMember {
  id: string;
  name: string;
  email: string;
  blocked: boolean;
  role: UserRole;
  joined: string;
}

export type UserRole = 'admin' | 'manager' | 'owner' | 'creator' | 'viewer';
