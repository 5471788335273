import React from 'react';
import AppLogo from './AppLogo';
import styles from './AppLoadingScreen.module.css';

function AppLoadingScreen() {
  return (
    <div className={styles.container}>
      <AppLogo />
      <span className={styles.text}>Loading...</span>
    </div>
  );
}

export default AppLoadingScreen;
