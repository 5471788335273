import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Input, Modal, Typography } from 'antd';
import useAnalytics from 'hooks/useAnalytics';
import { useContext, useRef, useState } from 'react';
import { AdminConsoleContext } from 'routes/Admin/AdminConsoleProvider';
import { createGroup } from 'services/members';

const { Paragraph } = Typography;

export default function CreateGroupModal(props: { open: boolean; onCancel: any; onSuccess: any }) {
  const { organization } = useContext(AdminConsoleContext);
  const { open, onCancel, onSuccess } = props;
  const [name, setName] = useState('');
  const [hasError, setHasError] = useState(false);
  const inputRef = useRef<any>();
  const analytics = useAnalytics();

  const queryClient = useQueryClient();
  const mutation = useMutation((data: string) => createGroup(data), {
    onSuccess: (data) => {
      queryClient.setQueryData(['organization', organization?.id, 'groups'], (oldData: any) => {
        return [...oldData, ...data];
      });
      analytics.trackEvent('Admin: Added Group');
      onSuccess(data);
    },
  });

  const handleOk = async () => {
    if (hasError) return;
    const groupName = inputRef.current.input.value;
    mutation.mutate(groupName);
  };

  const handleCancel = () => {
    setHasError(false);
    onCancel();
  };

  const handleChange = (e: any) => {
    const input = e.target.value;
    const allowedCharacters = /^[a-zA-Z0-9 +-]+$/;
    setHasError(!(input.match(allowedCharacters) || input === ''));
    setName(input);
  };

  return (
    <Modal
      title="Add group"
      open={open}
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={name === '' || hasError ? { disabled: true } : { disabled: false }}
      confirmLoading={mutation.isLoading}
      destroyOnClose
      style={{ zIndex: 10 }}
    >
      <Paragraph>Enter a name for the group you want to add.</Paragraph>
      <Input
        ref={inputRef}
        placeholder="Group name"
        onChange={handleChange}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') handleOk();
        }}
        autoFocus
        status={hasError ? 'error' : undefined}
      />
      {hasError && <Paragraph type="danger">Invalid characters</Paragraph>}
    </Modal>
  );
}
