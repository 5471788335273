import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppLoadingScreen from 'components/AppLoadingScreen';
import { SimumatikDevTools } from 'components/SimumatikDevTools';
import { authConfig } from 'config/auth_config';
import AntDesignProvider from 'providers/AntDesignProvider';
import Auth0ProviderWithHistory from 'providers/Auth0ProviderWithHistory';
import FeatureFlagProvider from 'providers/FeatureFlagProvider';
import { PlausibleProvider } from 'providers/PlausibleProvider';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { UpgradeSuccess } from 'routes';
import { VerifyEmail } from 'routes/VerifyEmail';
import { Fetch } from 'simumatik-commons';
import RollbarProvider from 'utils/RollbarProvider';
import './index.css';
import Signup from './pages/signup';
import * as serviceWorker from './serviceWorker';

const ProtectedRoutes = React.lazy(() => import('./ProtectedRoutes'));
const News = React.lazy(() => import('./features/News'));

Fetch.baseURL = authConfig.audience;
const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <RollbarProvider>
    <PlausibleProvider>
      <FeatureFlagProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <SimumatikDevTools />
          <BrowserRouter>
            <Auth0ProviderWithHistory>
              <AntDesignProvider>
                <Router>
                  <Switch>
                    <Route path="/signup">
                      <Signup />
                    </Route>
                    <Route path="/upgrade-success">
                      <UpgradeSuccess />
                    </Route>
                    <Route path="/news">
                      <Suspense fallback="Loading...">
                        <News />
                      </Suspense>
                    </Route>
                    <Route path="/verify-email">
                      <VerifyEmail />
                    </Route>
                    <Route path="/">
                      <Suspense fallback={<AppLoadingScreen />}>
                        <ProtectedRoutes />
                      </Suspense>
                    </Route>
                  </Switch>
                </Router>
              </AntDesignProvider>
            </Auth0ProviderWithHistory>
          </BrowserRouter>
        </QueryClientProvider>
      </FeatureFlagProvider>
    </PlausibleProvider>
  </RollbarProvider>,
);

serviceWorker.unregister();
